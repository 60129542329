import React, { useEffect, useState } from "react";
import contractAbi from './contractAbi';
import {
  Divider,
  Tooltip,
  List,
  Avatar,
  Spin,
  Tabs,
  Input,
  Button,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import logo from "../noImg.png";
import axios from "axios";
import { CHAINS_CONFIG } from "../chains";
import { ethers } from "ethers";
import { utils } from 'ethers-utils';

import Web3 from 'web3';
import Modal from "./model";

function WalletView({
  wallet,
  setWallet,
  seedPhrase,
  setSeedPhrase,
  selectedChain,
}) {
  const navigate = useNavigate();
  const [tokens, setTokens] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [balance, setBalance] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [amountToSend, setAmountToSend] = useState(null);
  const [sendToAddress, setSendToAddress] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [hash, setHash] = useState(null);

  const [tokenAddress, setTokenAddress] = useState();

  const [data, setData] = useState('');

  const [balance1, setBalance1] = useState(0);


  // ---model===
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // ---model===

  console.log("Token address is ",tokenAddress)
  const handleCopyClick = () => {
    // Copy the hexadecimal value to the clipboard
    navigator.clipboard.writeText(data);
    // alert('Hexadecimal value copied to clipboard!');
  };

 
  
  useEffect(() => {
    const address2 = wallet;
    const fetchBalance = async () => {
      try {
        const web3 = new Web3('https://rpc.bstchain.io');
        const balanceWei = await web3.eth.getBalance(address2);
        let balanceBSTC = web3.utils.fromWei(balanceWei, 'ether');
        
        // Convert balanceBSTC to a number, subtract 1, and then convert it back to a string
        balanceBSTC = (parseFloat(balanceBSTC) - 1).toString();
    
        setBalance1(balanceBSTC);
        console.log("balance is ", balanceBSTC);
        // alert("balance is ", balanceBSTC);
    } catch (error) {
        console.error('Error fetching balance:', error);
    }
    
    };

    fetchBalance();

    // Clean-up function if needed
    return () => {
      // Any clean-up code here if necessary
    };
  }, [selectedChain,loading , setLoading]); 
  const items = [
    {
      key: "3",
      label: `Tokens`,
      
      children: (
        <>
          {tokens ? (
            <>
              <List
                bordered
                itemLayout="horizontal"
                dataSource={tokens}
                renderItem={(item, index) => (
                  <List.Item style={{ textAlign: "left" }} className="list" onClick={()=>{
                    copyToClipboard(item.token_address)
                  }}>
                    <List.Item.Meta
                      avatar={<Avatar src={item.logo || logo} />}
                      title={item.symbol}
                      // description={item.name} 
                      description = {item.token_address}
                    />
                  {/* <div className="addr">  {item.token_address} </div> */}
                    <div className="tokens">
                      {(
                        Number(item.balance) /
                        10 ** Number(item.decimals)
                      ).toFixed(2)}{" "}
                      Tokens 
                    </div>
                    {/* <div>
                    
                    </div> */}
                  </List.Item>
                )}
              />

<div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }} className="cw"> Token Address:</p>
            <Input
              value={tokenAddress}
              onChange={(e) => setTokenAddress(e.target.value)}
              placeholder="123..."
            />
          </div>
<div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }} className="cw"> To:</p>
            <Input
              value={sendToAddress}
              onChange={(e) => setSendToAddress(e.target.value)}
              placeholder="0x..."
            />
          </div>
          <div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }} className="cw"> Amount:</p>
            <Input
              value={amountToSend}
              onChange={(e) => setAmountToSend(e.target.value)}
              placeholder="Native tokens you wish to send..."
            />
          </div>
          <Button
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
            type="primary"
            onClick={() => sendTokens(sendToAddress, amountToSend)}
          >
            Send Tokens
          </Button>
            </>
          ) : (
            <>
              <span className="cw">You seem to not have any tokens yet</span>
              
            </>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: `NFTs`,
      children: (
        <>
          {nfts ? (
            <>
              {nfts.map((e, i) => {
                return (
                  <>
                    {e && (
                      <img
                        key={i}
                        className="nftImage"
                        alt="nftImage"
                        src={e}
                      />
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              <span className="cw">You seem to not have any NFTs yet</span>
             
            </>
          )}
        </>
      ),
    },
    {
      key: "1",
      label: `Transfer`,
      children: (
        <>
          <h3 className="cw">Native Balance </h3>
          <h1 className="cw">
          </h1>
          {selectedChain === "1B7F" ? (
        <h1>{balance1} BSTC</h1>
      ) : (
        <h1 className="cw">
              {balance.toFixed(2)} {CHAINS_CONFIG[selectedChain].ticker}
        </h1>
      )}
          <div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }} className="cw"> To:</p>
            <Input
              value={sendToAddress}
              onChange={(e) => setSendToAddress(e.target.value)}
              placeholder="0x..."
            />
          </div>
          <div className="sendRow">
            <p style={{ width: "90px", textAlign: "left" }} className="cw"> Amount:</p>
            <Input
              value={amountToSend}
              onChange={(e) => setAmountToSend(e.target.value)}
              placeholder="Native tokens you wish to send..."
            />
          </div>
          <Button
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
            type="primary"
            onClick={() => sendTransaction(sendToAddress, amountToSend)}
          >
            Send Tokens
          </Button>
          {processing && <div className="loader">Loading...</div>}
          
      
          {processing && (
            <>
              <Spin />
              {hash && (
                <Tooltip title={hash}>
                  <p>Hover For Tx Hash</p>
                </Tooltip>
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: `Seed`,
      children: (
        <>
          <h3 className="cw">Seed Phrase</h3>
          <div>
        <span className="vaue" onClick={handleCopyClick}>{data}</span>
        </div>
        </>
      ),
    },
  ];


  async function sendTokens(to, amount) {
    
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
     // ====
    
     setTokenAddress("")
      setSendToAddress("")
      setAmountToSend("")

      if(selectedChain === "1B7F"){
        const web3 = new Web3('https://rpc.bstchain.io');
        
        // Sender's address and private key
        const address2 = wallet;
        const senderAddress = address2;
        const senderPrivateKey = ethers.Wallet.fromPhrase(seedPhrase).privateKey;
        
        // Recipient's address
        const recipientAddress = sendToAddress;
        
        // Amount to transfer (in BSTC, assuming 18 decimal places)
        const amountToSend = amountToSend; // Example: 100 BSTC
        
        // Construct the transaction object
        const transactionObject = {
          from: senderAddress,
          to: recipientAddress,
          value: web3.utils.toWei(amountToSend, 'ether'),
        };
        
        // Sign the transaction
        const signPromise = web3.eth.accounts.signTransaction(transactionObject, senderPrivateKey);
        
        signPromise.then((signedTx) => {
          // Send the signed transaction
          web3.eth.sendSignedTransaction(signedTx.rawTransaction)
            .on('receipt', receipt => {
              console.log('Transaction successful:', receipt);
              alert('Transaction successful:', receipt)
            })
            .on('error', error => {
              console.error('Transaction error:', error);
              alert('Transaction error:', error)
            });
        }).catch((error) => {
          console.error('Signing error:', error);
          alert('Signing error:', error)
        });
      }
      else{
        const chain = CHAINS_CONFIG[selectedChain];
    
        const provider = new ethers.JsonRpcProvider(chain.rpcUrl);
        
        const privateKey = ethers.Wallet.fromPhrase(seedPhrase).privateKey;
        
        const wallet = new ethers.Wallet(privateKey, provider);
        
        // Get the ERC20 contract instance
        const tokenContract = new ethers.Contract(tokenAddress, contractAbi, wallet);
        
        // Approve the token transfer
        const approvalTx = await tokenContract.approve(to, ethers.parseEther(amount.toString()));
        await approvalTx.wait();
        
        // Send the token transfer transaction
        const transferTx = await tokenContract.transfer(to, ethers.parseEther(amount.toString()));
        await transferTx.wait();
        
        
        // Rest of the code remains the same
        setProcessing(true);
        try {
          setHash(transferTx.hash);
          const receipt = await transferTx.wait();
          
          setHash(null);
          setProcessing(false);
          setAmountToSend(null);
          setSendToAddress(null);
          
          if (receipt.status === 1) {
            getAccountTokens();
          } else {
            console.log("failed");
          }
          setLoading(false);
          alert('Tokens sent successfully!');
        } catch (err) {
          setHash(null);
          setProcessing(false);
          setAmountToSend(null);
          setSendToAddress(null);
          setLoading(false);
          alert('Failed to send tokens.');
        }
        // finally {
        //   // Set loading to false after the process completes
        //   setLoading(false);
        // }
      }
    
    

  }

  // console.log("tokens are ", tokens[0]?.token_address)
  
  async function sendTransaction(to, amount) {

    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
     // ====

    const chain = CHAINS_CONFIG[selectedChain];

    const provider = new ethers.JsonRpcProvider(chain.rpcUrl);

    const privateKey = ethers.Wallet.fromPhrase(seedPhrase).privateKey;

    const wallet = new ethers.Wallet(privateKey, provider);

    const tx = {
      to: to,
      value: ethers.parseEther(amount.toString()),
    };

    setProcessing(true);
    try{
      const transaction = await wallet.sendTransaction(tx);

      setHash(transaction.hash);
      const receipt = await transaction.wait();

      setHash(null);
      setProcessing(false);
      setAmountToSend(null);
      setSendToAddress(null);

      if (receipt.status === 1) {
        getAccountTokens();
      } else {
        console.log("failed");
      }

      alert('Tokens sent successfully!');

    }catch(err){
      setHash(null);
      setProcessing(false);
      setAmountToSend(null);
      setSendToAddress(null);
      alert('Failed to send tokens.');
    }
    finally {
      // Set loading to false after the process completes
      setLoading(false);
      setProcessing(false);
      setAmountToSend(null);
      setSendToAddress(null);
    }
  }

  

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('Text copied to clipboard: ', content);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  async function getAccountTokens() {
    setFetching(true);

    const res = await axios.get(`https://chromebackend.vercel.app/getTokens`, {
      params: {
        userAddress: wallet,
        chain: selectedChain,
      },
    });

    const response = res.data;

    if (response.tokens.length > 0) {
      setTokens(response.tokens);
      // alert(response.tokens[0].token_address)
      // setTokenAddress(response.tokens[0].token_address);
      console.log(response.tokens[0].token_address)
    }

    if (response.nfts.length > 0) {
      setNfts(response.nfts);
    }

    setBalance(response.balance);

    setFetching(false);
  }

  function logout() {
    setSeedPhrase(null);
    setWallet(null);
    setNfts(null);
    setTokens(null);
    setBalance(0);
    navigate("/");
  }

  useEffect(() => {
    if (!wallet || !selectedChain) return;
    setNfts(null);
    setTokens(null);
    setBalance(0);
    getAccountTokens();
  }, []);

  useEffect(() => {
    if (!wallet) return;
    setNfts(null);
    setTokens(null);
    setBalance(0);
    getAccountTokens();
  }, [selectedChain]);

  useEffect(() => {
    // Fetch data from local storage when the component mounts
    const storedData = localStorage.getItem('key');

    // Update state with the fetched data if it exists
    if (storedData) {
      setData(storedData);
    }
  }, []); 


   
  
    
  return (
    <>
      <div className="content">
        <div className="logoutButton" onClick={logout}>
          <LogoutOutlined />
        </div>
        <div className="walletName">Wallet</div>
        <Tooltip title={wallet}>
          <div>
            {wallet.slice(0, 4)}...{wallet.slice(38)}
          </div>
        </Tooltip>
        <br/>
        {/* <div className="walletName">Phrase Seed</div> */}

       
        <Divider />
        {fetching ? (
          <Spin />
        ) : (
          <Tabs defaultActiveKey="1" items={items} className="walletView" />
        )}
      </div>
      
    </>
  );
}

export default WalletView;
