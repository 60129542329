const Ethereum = {
    hex: '0x1',
    name: 'Ethereum',
    rpcUrl: '',
    ticker: "ETH"
};

const MumbaiTestnet = {
    hex: '0x13881',
    name: 'Mumbai Testnet',
    rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    ticker: "MATIC"
};

const BSCMainnet = {
    hex: '0x38',
    name: 'Binance Smart Chain (Mainnet)',
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    ticker: 'BNB'
};

const BSTChain = {
    hex: '1B7F',
    name: 'BST Chain',
    rpcUrl: 'https://rpc.bstchain.io',
    ticker: 'BSTC'
};
export const CHAINS_CONFIG = {
    "0x1": Ethereum,
    "0x13881": MumbaiTestnet,
     "0x38":BSCMainnet,
     "1B7F":BSTChain

};